import React from "react";
import {
  SEO,
  PageLayout,
  Image,
  SlideReveal,
  multipleComponentIterator,
  Location,
  Map,
  Email,
  Phone,
  HoursOfOperation,
} from "@bluefin/components";
import { Grid, Header } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";

export default class ContactPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
          title={fishermanBusinessWebsitePage.seoTitle}
        />
        <PageLayout className={"contact-page"} hero={null}>
          <Grid
            stackable={true}
            textAlign={"left"}
            className={"contact-info-grid"}
          >
            <Grid.Column width={16} className={"header-column"}>
              <Header as={"h1"}>
                <span
                  className={"no-highlight"}
                  children={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "contact_header",
                  })}
                />
                <span
                  className={"highlight"}
                  children={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentId: "contact_header_highlighted",
                  })}
                />
              </Header>
            </Grid.Column>
            <Grid.Row>
              <Grid.Column width={8} className={"hero-image-column"}>
                <Image
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentIdentifier: "contact_image",
                    numToSelect: 1,
                  })}
                />
              </Grid.Column>
              <Grid.Column width={8} className={"image-gallery-column"}>
                <Grid>
                  <SlideReveal
                    fade={true}
                    triggerOnce={true}
                    direction={"up"}
                    cascade={true}
                    className={"ui column eight wide"}
                    children={multipleComponentIterator({
                      iterator: getComponentFiles({
                        components: fishermanBusinessWebsitePage.components,
                        componentIdentifier: "contact_gallery_images",
                        numToSelect: 4,
                      }),
                      limit: 4,
                      components: [
                        {
                          component: <Grid.Column width={8} />,
                          propMap: { key: "_id" },
                          children: [
                            {
                              component: <Image />,
                              propMap: { src: "__all__" },
                            },
                          ],
                        },
                      ],
                    })}
                  />
                </Grid>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8} className={"form-column"}>
                <div className={"location-info-container"}>
                  <Header as={"h2"}>Location</Header>
                  <div>
                    <Location
                      address={{
                        street: fishermanBusiness.primaryLocation.street,
                        state: fishermanBusiness.primaryLocation.state,
                        zip: fishermanBusiness.primaryLocation.zipCode,
                        city: fishermanBusiness.primaryLocation.city,
                      }}
                      businessName={fishermanBusiness.name}
                      showBusinessName={false}
                    />
                    <Map
                      address={{
                        street: fishermanBusiness.primaryLocation.street,
                        state: fishermanBusiness.primaryLocation.state,
                        zip: fishermanBusiness.primaryLocation.zipCode,
                        city: fishermanBusiness.primaryLocation.city,
                      }}
                      businessName={fishermanBusiness.name}
                    />
                  </div>
                </div>
              </Grid.Column>
              <Grid.Column width={8} className={"info-column"}>
                <div className={"contact-info-container"}>
                  <Header as={"h2"}>Contact</Header>
                  <div>
                    <Email email={fishermanBusiness.primaryLocation.email} />
                    <Phone
                      phone={fishermanBusiness.primaryLocation.phoneNumber}
                      event={{
                        category: "Contact",
                        action: "Call",
                        label: "Contact Page",
                      }}
                    />
                  </div>
                </div>
                <div className={"hours-info-container"}>
                  <Header as={"h2"}>Hours</Header>
                  <div>
                    <HoursOfOperation
                      hours={fishermanBusiness.primaryLocation.hours}
                      displayOption={"grouped"}
                      enhancedLabels={{
                        openingSoon: "Opening soon",
                        open: "Open now",
                      }}
                    />
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      primaryLocation {
        street
        state
        zipCode
        city
        email
        phoneNumber
        hours {
          day
          open
          close
          label
        }
      }
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Contact" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        contentNodes {
          content
        }
      }
      seoTitle
    }
  }
`;
